import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// styled components
import {StyledListContent, StyledListTitle, StyledListWrapper} from '../../styledComponents/list/list.styled';
import StyledButton, {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledCardHeaderTitle} from '../paymentsForm/templateOne/web/formWeb.styled';
import {StyledSelectOptionsHeader} from '../../styledComponents/select/select.styled';

function SafetyPayHistoryDetails({
  paymentData,
  onPayHandler,
  onCloseHandler,
  isMobile
}) {
  const {t} = useTranslation();

  return (
    <div>
      {isMobile ? null : (
        <StyledSelectOptionsHeader>
          <StyledButtonWithoutBg type="button" onClick={onCloseHandler} isPositionLeft>
            <StyledIconSmall icon="close" fontSize="11px" />
          </StyledButtonWithoutBg>
          <StyledCardHeaderTitle>{t('order')}</StyledCardHeaderTitle>
        </StyledSelectOptionsHeader>
      )}
      <StyledListWrapper
        isMobile={false}
        hasMarginBottom
      >
        <StyledListTitle>{t('guideTitle')}</StyledListTitle>
        <StyledListContent>
          {paymentData?.howto_pay_steps?.map((item) => (
            <li key={item.step_number}>{item.step_instruction}</li>
          ))}
        </StyledListContent>
      </StyledListWrapper>
      {paymentData?.location_url ? (
        <StyledButton
          type="button"
          onClick={() => onPayHandler(paymentData.location_url)}
        >
          {t('Pay')}
        </StyledButton>
      ) : null}
    </div>
  );
}

SafetyPayHistoryDetails.propTypes = {
  paymentData: PropTypes.object.isRequired,
  onPayHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};

SafetyPayHistoryDetails.defaultProps = {
  isMobile: false
};

export default memo(SafetyPayHistoryDetails);
