import instance from './apiService';
import {OPERATION_TYPE_BY_NUMBER, PAYMENTS_IDS} from '../constants/common';
import {
  flattenObject, isIOSWebView, isMobileApp, updateTransactionsData
} from '../helpers/helperFunction';
import {merchantUserMultistepControls} from './merchantUserMultistepControls';
import {getSitePaymentSystemControls} from './getSitePaymentSystemControls';

/**
 * Handles the form submission for various payment transactions, sending a request to the specified API endpoint.
 *
 * @param {function} setIsLoading - A function to set the loading status.
 * @param {function} setPaymentControlsData - A function to set payment controls data.
 * @param {function} setPaymentGeneratedCodeInfo - A function to set site payment system generated Code Info.
 * @param {boolean} hasIframe - Indicates whether the transaction uses an iframe.
 * @param {string} language - The language code (ISO format).
 * @param {function} setApplePayTransactionData - A function to set Apple Pay transaction data.setPayPalTransactionData
 * @param {function} setPayPalTransactionData - A function to set PayPal transaction data.
 * @param {function} setGooglePayTransactionData - A function to set Google Pay transaction data.
 * @param {object} transactionDataSetters - A object for transactions Data update.
 * @param {object} alertContext - The context object for displaying alerts.
 * @param {function} alertContext.error - A function to display an error alert.
 * @param {boolean} hasRedirectUrl - Indicates whether there is a redirect URL.
 * @param {object} templateTranslation - Object containing translated template strings.
 * @param {string} bonusId - The bonus id code.
 * @param {string} gaMeasurementId
 * @param {number} minAmountValue - The minimum value of amount if amountValues absent.
 * @param {function} setPaymentSources - A function to set site payment system generated Code Info.
 *
 * @returns {Function} - A function that handles the actual form submission based on the specified parameters.
 *
 * @throws {Error} - If the request to the API fails, an error object is thrown.
 *
 * @example
 * const setIsLoading = (isLoading) => {
 *   // Set loading status in the UI
 * };
 *
 * const setPaymentControlsData = (paymentControls) => {
 *   // Set payment controls data in the UI
 * };
 *
 *
 * const hasIframe = true;
 * const language = 'en';
 * const setApplePayTransactionData = (applePayTransactionData) => {
 *   // Set Apple Pay transaction data in the UI
 * };
 * const alertContext = {
 *   error: (errorMessage) => {
 *     // Display an error alert in the UI
 *     console.error(errorMessage);
 *   },
 *   success: (successMessage) => {
 *     // Display a success alert in the UI
 *     console.log(successMessage);
 *   }
 * };
 * const hasRedirectUrl = true;
 * const templateTranslation = {
 *   successTransaction: 'Transaction successful!',
 *   // Other translated template strings
 * };
 *
 * const handleSubmit = handleSubmitForm(
 *   setIsLoading,
 *   setPaymentControlsData,
 *   setSitePaymentSystemControls,
 *   hasIframe,
 *   language,
 *   setApplePayTransactionData,
 *   alertContext,
 *   hasRedirectUrl,
 *   templateTranslation,
 *   bonusId
 * );
 *
 * handleSubmit(
 *   'https://example.com/api/submitTransaction',
 *   '123',
 *   'paymentSystem123',
 *   'platformPaymentSystem456',
 *   'site123',
 *   'USD',
 *   'purchase',
 *   { /* Form values object * / },
 *   'xyzToken',
 *   true,
 *   secondaryUrl,
 *   2,
 *   'post',
 *   '123'
 * );
 */
export const handleSubmitForm = (
  setIsLoading,
  setPaymentControlsData,
  setPaymentGeneratedCodeInfo,
  hasIframe,
  language,
  setApplePayTransactionData,
  setPayPalTransactionData,
  setGooglePayTransactionData,
  transactionDataSetters,
  alertContext,
  hasRedirectUrl,
  templateTranslation,
  bonusId,
  gaMeasurementId,
  minAmountValue,
  setPaymentSources
) => ({
  url,
  userId,
  paymentSystemId,
  platformPaymentSystemId,
  siteId,
  currency,
  operationType,
  payloadValues,
  token,
  isMultiStep,
  secondaryUrl,
  dynamicUrlProps
}) => {
  const flattenedData = flattenObject(payloadValues);
  const stringifyValues = JSON.stringify(flattenedData);
  const amountValues = payloadValues?.amount === undefined ? null : (JSON.parse(payloadValues?.amount).value ?? payloadValues.amount);

  const isTransactionCreateApi = url.includes('TransactionCreate');
  const isAccountVerifyApi = url.includes('setMerchantUserBankAccount');

  const {
    withParams,
    httpMethodType, // 'GET' or 'POST'
    step
  } = dynamicUrlProps;

  const requestBody = isAccountVerifyApi ? {
    siteId,
    paymentSystemId,
    userId: userId.toString(),
    bankAccountNumber: payloadValues.accountnumber,
    countryISO: currency,
    paymentMethodType: operationType,
    proofImageInfo: payloadValues.proofImageInfo
  } : {
    userId: userId.toString(),
    paymentSystemId,
    platformPsId: platformPaymentSystemId,
    siteId,
    amount: paymentSystemId === PAYMENTS_IDS.DEPOZITRON
      ? localStorage.getItem('amountValue') || minAmountValue
      : amountValues || minAmountValue,
    currencyId: currency,
    languageId: language,
    transactionType: operationType.toString(),
    transactionData: stringifyValues,
    paymentToken: token,
    bonusId,
    gaMeasurementId
  };

  setIsLoading(true);
  let w1 = '';
  if (hasRedirectUrl && !hasIframe && isTransactionCreateApi && !isMobileApp() && !isIOSWebView()) {
    w1 = window.open('https://pyg.letspayments.com/finalpage?r=3', '_blank');
  }

  if (withParams === 'true') {
    const constructedUrl = `${url}/${siteId}/${paymentSystemId}/${operationType}/${Number(step)}`;
    if (httpMethodType.toUpperCase() === 'GET') {
      instance.get(
        constructedUrl,
        {
          param: {
            siteId,
            paymentSystemId,
            operationType,
            step
          }
        }
      )
        .then((response) => {
          setPaymentControlsData(response.data);
        })
        .catch((error) => {
          alertContext.error(error.response.data);
        })
        .finally(() => setIsLoading(false));
    }
  } else {
    instance.post(url, requestBody)
      .then((response) => {
        if (response.data?.paymentSources && !secondaryUrl) {
          setPaymentSources(response?.data?.paymentSources);
        }
        if (secondaryUrl) {
          setPaymentGeneratedCodeInfo(response.data?.paymentSources);
        } else {
          const redirectUrl = response?.data?.redirectUrl;
          if (hasRedirectUrl && redirectUrl && !isMobileApp() && !isIOSWebView()) {
            w1.location.href = redirectUrl;
          } else if (hasRedirectUrl && redirectUrl) {
            if (isIOSWebView()) {
              setTimeout(() => {
                // w1 = window.open(redirectUrl, '_blank');
                w1 = window.open(redirectUrl, '_blank');
                if (!w1 || w1.closed || typeof w1.closed === 'undefined') {
                  //blocked pop-up case
                  window.location.href = redirectUrl;
                }
              }, 100);
            } else {
              w1 = window.open(redirectUrl, '_blank');
            }
          }
          if (paymentSystemId === PAYMENTS_IDS.APPLE_PAY_ID
            || paymentSystemId === PAYMENTS_IDS.PAYPAL_ID
            || paymentSystemId === PAYMENTS_IDS.GOOGLE_PAY_ID
            || PAYMENTS_IDS.SAFETY_PAY_IDS.indexOf(paymentSystemId) !== -1
          ) {
            updateTransactionsData(paymentSystemId, response, transactionDataSetters, {amount: amountValues || minAmountValue, currencyId: currency});
            getSitePaymentSystemControls(
              setPaymentControlsData,
              siteId,
              paymentSystemId,
              operationType,
              2,
              setIsLoading,
              alertContext
            );
          }
          if (isMultiStep) {
            merchantUserMultistepControls(
              setPaymentControlsData,
              siteId,
              paymentSystemId,
              operationType,
              userId,
              currency,
              language,
              setIsLoading,
              alertContext
            );
          }
        }
        if (operationType === OPERATION_TYPE_BY_NUMBER.WITHDRAWAL) {
          alertContext?.success(templateTranslation?.successtransaction);
        }
      })
      .catch((error) => {
        alertContext?.error(error?.response?.data);
        if (hasRedirectUrl && !hasIframe && isTransactionCreateApi && !isMobileApp() && !isIOSWebView()) {
          w1.close();
        }
      })
      .finally(() => setIsLoading(false));
  }
};


