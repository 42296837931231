/**
 * All constant values that used in project
 */
import {
  AccountIcon,
  AddressIcon,
  AmexIcon,
  BankIcon,
  CardsIcon,
  DinersIcon,
  DiscoverIcon,
  DocsIcon,
  EmailIcon,
  JCBIcon,
  LaserIcon,
  MaestroIcon,
  MasterIcon,
  PhoneIcon,
  SoloIcon,
  SwitchIcon,
  VisaIcon,
  DefaultCardIcon,
  WalletIcon
} from '../assets/icons';
import {PAYMENT_CONTROL_TYPE} from './formControlType';

/**
 * GETTING DEVICE TYPE
 * */
export const DEVICE_TYPE = {
  WEB: 1,
  MOBILE: 2
};

/**
 * GETTING TEMPLATE TYPE
 * */
export const TEMPLATE_TYPE = {
  TEMPLATE_ONE: 1,
  TEMPLATE_TWO: 2
};

export const OPERATION_TYPE_BY_NUMBER = {
  DEPOSIT: 1,
  WITHDRAWAL: 2
};

export const CONTROL_STATE = {
  SUCCESS: 1,
  FAIL: 2,
  IN_PROCESS: 3
};

export const CODE_TYPE = {
  BAR_CODE: 'BarCode',
  QR_CODE: 'QrCode',
  QR_GENERATE: 'QR',
  DESCRIPTION: 'Description',
  AMOUNT: 'Amount'
};

export const HISTORY_TAB = {
  NEW: 2,
  PAID: 3,
  EXPIRED: 4
};

export const MOBILE_SIZE = '800px';
export const TABLET_SIZE = '1220px';

export const HOVER_MOTIONS_LIST = {
  NOTHING: 'nothing',
  ZOOM: 'zoom',
  BUTTON: 'button'
};

export const buttonControls = [
  PAYMENT_CONTROL_TYPE.BUTTON_WIDTH_URL,
  PAYMENT_CONTROL_TYPE.BUTTON_WITH_MULTI_URL
];

export const DEFAULT_LANGUAGE = 'default';

export const PAYPAL_CLIENT_ID = 'Af2G3tXRi_Ts8vZ1xzRgm_zeu0jCnvswmPYe_1VpooWHG91VmZ6d8d_cGb5slWoddC3WLz_Ne00ahx0O';
export const INTENT = 'authorize';

export const BASE_URL = 'https://pygcashierapi.tst-digi.com/api/';

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8'
};

export const DROPDOWN_ICONS = {
  account: AccountIcon,
  address: AddressIcon,
  bank: BankIcon,
  card: CardsIcon,
  document: DocsIcon,
  email: EmailIcon,
  phone: PhoneIcon,
  wallet: WalletIcon
};

export const CARD_TYPE_ICONS = {
  0: DefaultCardIcon,
  1: VisaIcon,
  2: MasterIcon,
  3: AmexIcon,
  4: DinersIcon,
  5: DiscoverIcon,
  6: JCBIcon,
  7: LaserIcon,
  8: MaestroIcon,
  9: SoloIcon,
  10: SwitchIcon
};

export const TRANSACTION_STATUSES = {
  APPROVED: 'APPROVED',
  ERROR: 'ERROR',
  DECLINED: 'DECLINED'
};

export const BASE_REQUEST = {
  API_VERSION: 2,
  API_VERSION_MINOR: 0
};

export const TIME_UNITS = {
  HOURLY: 6,
  MINUTE: 8,
  INSTANT: 0
};

export const DEFAULT_PAYMENT_ALERTS = {
  1: {
    set: 'paymentSetAsDefaultForDeposit',
    unSet: 'paymentUnsetFromDefaultForDeposit',
    error: 'paymentDefaultRequestErrorForDeposit'
  },
  2: {
    set: 'paymentSetAsDefaultForWithdrawal',
    unSet: 'paymentUnsetFromDefaultForWithdrawal',
    error: 'paymentDefaultRequestErrorForWithdrawal'
  }
};

export const ACCOUNT_VERIFICATION_STATUSES = {
  VERIFIED: 'Verified',
  IN_REVIEW: 'In Review',
  DECLINED: 'Verification Declined'
};

export const groupNameMapping = {
  1: 'bankTransfer',
  2: 'creditAndDebitCards',
  3: 'eVoucherPrepaid',
  4: 'cash',
  5: 'crypto',
  6: 'eWallet',
  7: 'mobilePayment',
  102: 'recommended',
  103: 'favorite',
  '-2': 'recommended'
};

export const SAFETY_PAY_IDS = [2131, 2179, 2172, 2178, 2173, 2175, 2171, 2170, 2177, 2174];
