import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '../themeProvidere/themeProvider';
import {templateComponents} from '../configs/mainConfig';
import {SimplifiedDataContext} from '../contexts/simplifiedDataContext';
import {PaymentControlsContext} from '../contexts/paymentControlsContext';
import {PaymentHistoryCallbacksContext} from '../contexts/paymentHistoryCallbacksContext';
import {SelectedPaymentDetailsContext} from '../contexts/selectedPaymentDetailsContext';

function PaymentsHistory({
  deviceType,
  templateType,
  paymentControlsData,
  historyData,
  getSelectedTab,
  customizationTheme,
  simplifiedData,
  handlePaymentSystemDetailsPage,
  navigate,
  translations,
  isCurrencySymbol,
  alertContext,
  paymentDetails,
  onSafetyPayHelper
}) {
  const DynamicComponent = templateComponents[templateType]?.[deviceType]?.HistoryComponent;
  const callbacksMemoized = useMemo(() => ({handlePaymentSystemDetailsPage}), [handlePaymentSystemDetailsPage]);

  if (DynamicComponent) {
    return (
      <ThemeProvider theme={customizationTheme}>
        <SimplifiedDataContext.Provider value={simplifiedData}>
          <PaymentHistoryCallbacksContext.Provider value={callbacksMemoized}>
            <SelectedPaymentDetailsContext.Provider value={paymentDetails}>
              <PaymentControlsContext.Provider value={paymentControlsData}>
                <DynamicComponent
                  paymentControlsData={paymentControlsData}
                  getSelectedTab={getSelectedTab}
                  historyData={historyData}
                  simplifiedData={simplifiedData}
                  handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                  navigate={navigate}
                  translations={translations}
                  isCurrencySymbol={isCurrencySymbol}
                  alertContext={alertContext}
                  onSafetyPayHelper={onSafetyPayHelper}
                />
              </PaymentControlsContext.Provider>
            </SelectedPaymentDetailsContext.Provider>
          </PaymentHistoryCallbacksContext.Provider>
        </SimplifiedDataContext.Provider>
      </ThemeProvider>
    );
  }

  return null;
}

PaymentsHistory.defaultProps = {
  deviceType: null,
  templateType: null,
  paymentControlsData: null,
  historyData: null,
  getSelectedTab: null,
  customizationTheme: null,
  simplifiedData: null,
  handlePaymentSystemDetailsPage: null,
  navigate: null,
  translations: null
};

PaymentsHistory.propTypes = {
  deviceType: PropTypes.number,
  templateType: PropTypes.number,
  paymentControlsData: PropTypes.object,
  historyData: PropTypes.array,
  getSelectedTab: PropTypes.func,
  customizationTheme: PropTypes.object,
  simplifiedData: PropTypes.array,
  handlePaymentSystemDetailsPage: PropTypes.func,
  navigate: PropTypes.func,
  translations: PropTypes.object,
  isCurrencySymbol: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  onSafetyPayHelper: PropTypes.func.isRequired
};

export default PaymentsHistory;
