import React, {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Config from '../../../configs/mainConfig';
import {controlValidationScheme, formattedControls, initialValues} from '../../../helpers/common';
import {buttonControls} from '../../../constants/common';
import {PaymentControlsContext} from '../../../contexts/paymentControlsContext';
import {CashierDetailsContext} from '../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../contexts/selectedPaymentDetailsContext';
import {PaymentFormCallbacksContext} from '../../../contexts/paymentFormCallbacksContext';

function EnhancedFormik({
  baseUrl,
  applePaySession,
  alertContext,
  autoGeneratedForm,
  payPalTransactionData,
  googlePayTransactionData,
  formComponent: FormComponent,
  deviceType,
  isCurrencySymbol
}) {
  const {
    siteId,
    userId,
    operationType,
    currency,
    language,
    token
  } = useContext(CashierDetailsContext);
  const {platformPaymentSystemId, isOffline, multistep: isMultiStep} = useContext(SelectedPaymentDetailsContext);
  const paymentControlsData = useContext(PaymentControlsContext);
  const {handleSubmitForm} = useContext(PaymentFormCallbacksContext);
  const [url, setUrl] = useState(null);
  const [secondaryUrl, setSecondaryUrl] = useState('');
  const [mergedControls, setMergedControls] = useState([]);
  const controlsFields = paymentControlsData?.fields;
  const formikRef = useRef({});
  const [selectedValue, setSelectedValue] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const foundDynamicControlGroupItems = controlsFields?.find((field) => field.dynamicControlGroupItems);
  const [dynamicUrlProps, setDynamicUrlProps] = useState({});

  /**
   *   Search for the "ButtonWithUrl && ButtonWithMultiUrl" control in the response
   *    Use optional chaining to check if buttonWithUrlControl is defined
   *    Set the URL state with dynamicUrl or a static URL if not found
   */
  const findFieldControlValue = (buttonWithUrlControl, controlPropertyTypeName) => buttonWithUrlControl?.fieldControls?.find(
    (controlProperty) => controlProperty.controlPropertyTypeName === controlPropertyTypeName
  )?.value;
  const ref = useRef({uploadedFiles});

  ref.current.uploadedFiles = uploadedFiles;

  const handleUrlCheck = () => {
    const buttonWithUrlControl = paymentControlsData?.fields?.find((field) => buttonControls.includes(field.controlTypeId));

    const dynamicUrl = findFieldControlValue(buttonWithUrlControl, 'url');
    const secondUrl = findFieldControlValue(buttonWithUrlControl, 'secondaryurl');

    const step = findFieldControlValue(buttonWithUrlControl, 'step');
    const withParams = findFieldControlValue(buttonWithUrlControl, 'withparams');
    const httpMethodType = findFieldControlValue(buttonWithUrlControl, 'httpmethodtype');

    setUrl(dynamicUrl || `${baseUrl}${Config.apiURLs.transactionCreate}`);
    setSecondaryUrl(secondUrl || '');
    setDynamicUrlProps({
      step,
      withParams,
      httpMethodType
    });
  };

  useEffect(() => {
    handleUrlCheck();
  }, [paymentControlsData]);

  const handlePreviousValue = (newVal) => {
    formikRef.current = {
      ...formikRef.current,
      ...newVal
    };
  };

  const formikEnhancer = withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object()
      .shape(
        controlValidationScheme(
          mergedControls?.filter(({name}) => name !== 'UploadDocument '),
          formattedControls(mergedControls?.filter(({name}) => name !== 'UploadDocument ')),
          {},
          language
        )
      ),

    mapPropsToValues: ({initialProps}) => ({
      ...initialProps
    }),

    handleSubmit: (payload, {setSubmitting}) => {
      setSubmitting(false);
      const payloadValues = Object.keys(payload)
        .reduce((acc, key) => {
          if (payload[key] !== '') {
            acc[key] = payload[key];
          }
          return acc;
        }, {});

      const {amount} = payload;

      /**
       * It's added for move from first step to second step info
       */
      if (dynamicUrlProps.withParams === 'true') {
        localStorage.setItem('amountValue', amount);
      }

      console.log('payloadValues', payload);

      handleSubmitForm({
        url,
        userId,
        paymentSystemId: paymentControlsData?.paymentSystemId,
        platformPaymentSystemId,
        payload,
        siteId,
        currency,
        operationType,
        payloadValues: {...payloadValues, proofImageInfo: ref.current.uploadedFiles},
        token,
        isMultiStep,
        secondaryUrl,
        dynamicUrlProps
      });
      handlePreviousValue({});
    }
  });

  const EnhancedForm = useMemo(() => formikEnhancer(FormComponent), [FormComponent, url, mergedControls]);

  useEffect(() => {
    if (!mergedControls?.length) {
      setMergedControls(paymentControlsData?.fields);
    }
  }, [paymentControlsData, selectedValue]);

  useEffect(() => {
    if (foundDynamicControlGroupItems && selectedValue) {
      setMergedControls([]);
      const {dynamicControlGroupItems} = foundDynamicControlGroupItems;
      const foundFields = dynamicControlGroupItems?.find((group) => group.groupId === +selectedValue.value);
      const penultimateIndex = controlsFields.length - 1;
      const updatedControls = [...controlsFields];
      if (foundFields?.fields) {
        updatedControls.splice(penultimateIndex, 0, ...foundFields.fields);
        setMergedControls(updatedControls);
      } else {
        setMergedControls(updatedControls);
      }

      setSelectedValue(selectedValue);
    } else {
      setMergedControls(controlsFields);
    }
  }, [selectedValue, controlsFields]);

  useEffect(() => {
    formikRef.current = {};
    setSelectedValue(null);
  }, [paymentControlsData?.paymentSystemId]);

  return (
    <EnhancedForm
      formattedControls={formattedControls(mergedControls)}
      controls={mergedControls}
      initialProps={{...initialValues(mergedControls), ...formikRef.current}}
      paymentSystemId={paymentControlsData?.paymentSystemId}
      applePaySession={applePaySession}
      alertContext={alertContext}
      setSelectedValue={setSelectedValue}
      selectedValue={selectedValue}
      handlePreviousValue={handlePreviousValue}
      favoriteAmounts={paymentControlsData?.favAmounts}
      autoGeneratedForm={autoGeneratedForm}
      isOffline={isOffline}
      payPalTransactionData={payPalTransactionData}
      googlePayTransactionData={googlePayTransactionData}
      setUploadedFiles={setUploadedFiles}
      isFileUploaded={ref.current.uploadedFiles.length > 0}
      deviceType={deviceType}
      foundDynamicControlGroupItems={foundDynamicControlGroupItems}
      currency={currency}
      isCurrencySymbol={isCurrencySymbol}
    />
  );
}

EnhancedFormik.defaultProps = {
  baseUrl: null,
  autoGeneratedForm: false,
  payPalTransactionData: null,
  googlePayTransactionData: null
};

EnhancedFormik.propTypes = {
  baseUrl: PropTypes.string,
  applePaySession: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  autoGeneratedForm: PropTypes.bool,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  formComponent: PropTypes.element.isRequired,
  deviceType: PropTypes.number.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired
};
export default memo(EnhancedFormik);
